import React from "react"


export default function Alevector(){



  React.useEffect(()=>{

    var hairR = document.getElementById('hairR')
    var hairL = document.getElementById('hairL')
    var fringe = document.getElementById('fringe')
    var face = document.getElementById('face')
    var ears = document.getElementById('ears')
    var mouth = document.getElementById('mouth')
    var nose = document.getElementById('nose')
    var brownL = document.getElementById('brownL')
    var brownR = document.getElementById('brownR')
    var eyes = document.getElementById('eyes')
    var pupils = document.querySelectorAll('.eye')
    
    
    let cursorPos = { x:0, y:0 }
    
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    const limit = 1100
    
    
    function setWindowSize(e) {
      windowWidth = window.innerWidth
      windowHeight = window.innerHeight
    }  
  
    function mouseMove(e) {
      cursorPos = { x: e.clientX, y: e.clientY, pageY: e.pageY}
        initFollow()
      
    }  
  
    function touchMove(e) {
      cursorPos = { x:e.touches[0].clientX, y: e.touches[0].clientY, pageY: e.touches[0].pageY}
      initFollow()
      console.log("ON", e.pageY);
    }  
    
    function followCursor(el, xRatio, yRatio) {
      const elementOffset = el.getBoundingClientRect()
      const centerX = elementOffset.x + elementOffset.width / 2
      const centerY = elementOffset.y + elementOffset.height / 2
      const distanceLeft = Math.round(((cursorPos.x - centerX) * 100) / windowWidth)
      let distanceTop

      cursorPos.pageY <= limit ? distanceTop = Math.round(((cursorPos.y - centerY) * 100) / windowHeight) : distanceTop = 50
      
      el.style.transform = `translate(${distanceLeft / xRatio}px, ${((distanceTop) / yRatio)}px)`
      
    }
  
    function initFollow() {
      
      if(brownR) followCursor(brownR, 6, 6)
      if(brownL) followCursor(brownL, 6, 6)

      if(mouth) followCursor(mouth, 6, 6)
      if(nose) followCursor(nose, 4.5, 5.5)
      if(hairR) followCursor(hairR, -10, -10)
      if(hairL) followCursor(hairL, -10, -10)
      if(fringe) followCursor(fringe, 10, 10)
      
      if(ears) followCursor(ears, 16, 16)
      if(face) followCursor(face, 16, 16)

      if(eyes) followCursor(eyes, 6, 7)

      if(pupils.length > 0){
        pupils.forEach((pupil)=>{
          followCursor(pupil, 4, 5)
          
        })
      } 

    }
  
  
    window.addEventListener('resize', setWindowSize)
    window.addEventListener('mousemove', mouseMove)
    window.addEventListener('touchmove', touchMove)
  
    return () => {
      window.removeEventListener('resize', setWindowSize)
      window.removeEventListener('mousemove', mouseMove)
      window.removeEventListener('touchmove', touchMove)
    }
  }, [])


    return(
        
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 377 420.1"
        style={{
          enableBackground: "new 0 0 900 800",
        }}
        xmlSpace="preserve"
        width="100%" height="100%"
        >
          <style>{".st0{fill:#0d1821}.st1{fill:#fff}"}</style>

          <g id="Torso">
            <g>
              <path className="st0" d="M226.4,227.6l6.1-0.8c0,4.1-0.3,21.9,0.1,26c1.4,15,1.4,30.3,5.8,45.3c3.2,1.5,6.4,3.1,9.8,4.6
                c0.9,0.4,1.8,0.9,2.8,0.9c12.1,0,22.6,5.4,32.9,10.8c14.3,7.5,29.1,13.9,43.9,20.2c6.7,2.9,13.3,6.3,19.5,10.2
                c13,8.3,22.5,19.4,26.1,34.8c1.1,5,2.6,9.9,3.5,15c0.9,4.5-0.2,6.8-4.3,9c-7.1,3.8-14.7,6.4-22.7,8c-15.6,3.2-31.5,4-47.3,5.6
                c-7,0.7-14.1,1.4-21.1,2.2c-15.5,1.6-30.8,0.2-46-3c-22.4-4.8-45-6.2-67.8-3.5c-8.7,1-17.4,2.4-26.2,3.2
                c-11.7,1.1-23.4,1.9-35.1,2.6c-11.4,0.6-23,1.1-34.4,1.3c-18.8,0.2-36.9-3.7-54.7-9.3c-5.1-1.6-9.8-4.3-14.7-6.7
                c-2.2-1.1-3-2.6-2.2-4.8c0.9-2.8,2-5.5,3.4-8.1c2.8-5.7,6-11.2,8.8-16.9c2.6-5.3,6.3-9.9,9.9-14.5c9.9-12.7,23.3-19.7,37.8-25.4
                c10.5-4.2,21.2-7.1,32.2-9.8c10.8-2.7,21.4-6.8,32-10.3c0.9-0.3,1.9-0.6,2.6-1.3c6.8-6.4,15.1-9.6,24.4-12.3
                c1.1-16.6-0.1-54.1-0.3-71.2l6.9-0.9"/>
              <path className="st1" d="M157.4,228.6c0.3,15.5,3.4,71.2,1,76.5c-4,2.1-8.1,4.2-12.2,6.5c-1.1,0.6-1.8,1.7-2.9,2.6
                c5.5,3.1,11.2,2.3,16.6,3.7c4.6,1.2,9,2.6,13.5,4.2c4,1.4,8.6,4.8,10.1,9c2.2,6.2,5.1,8.6,7.6,14.7c1,2.3,2.1,4.6,3.6,7.7
                c0.9-1.9,1.5-2.9,1.8-4c1.5-5.9,1.8-7.8,3.1-13.8c1-4.5,2-8.4,6.7-10.1c5.7-2.1,10.9-5.5,16.3-8.4c5.3-3,10.5-4.3,15.7-7.4
                c0.9-0.5,1.7-1.2,2.9-2.1c-3.3-1.8-6-3.3-9.3-5.2c-2.6-11.9-4.9-23.6-4.9-35.7c0-4.7,0-9.4-0.1-14.1c0-0.6-0.3-20.3-0.8-25"/>
              <path className="st0" d="M158.3,291l-1.9-51.6l84.3-2.6C240.8,236.8,226.3,280.4,158.3,291z"/>
              <path className="st1" d="M113,332.4c-10.6,3.7-21.2,7.4-31.9,10.7c-6,1.9-12.3,3-18.4,4.8c-6.5,2-13.1,4-18.6,8.3
                c-0.7,0.6-1.7,0.7-3.5,1.3c0.4-1.5,0.4-2.6,0.9-2.9c3.1-2,6.1-4,9.4-5.5c8.9-4.2,18.6-5.8,28-8.4c12.5-3.5,24.9-7,36.6-12.8
                c0.5-0.3,1.3,0,2,0c0.1,0,0.2,0.4,0.4,0.6C117.4,331.3,114.9,331.7,113,332.4z"/>
              <path className="st1" d="M165.4,355.2c-4.8-0.1-6.8-2.6-5.7-6.9c0.5-2.1,1.8-3.3,3.9-3.8c2.3-0.5,4.3-0.1,5.8,1.9
                c0.4,0.6,0.7,1.2,1,1.9C172.1,352.9,170.3,355.3,165.4,355.2z"/>
              <path className="st1" d="M140,323.9c-2-0.8-3.8-2.1-5.4-3.4c-0.6-0.5-0.4-2-0.7-4.5c7.8,6,15.4,7.9,22.9,9.8
                c5.8,1.5,11.7,3.1,17.5,4.8c0.7,0.2,1.2,1.2,2.4,2.6c-2.2,0-3.4,0.2-4.5,0C161.2,330.7,150.3,328.1,140,323.9z"/>
              <path className="st1" d="M179.1,395.1c-4.4,0-6.8-2.3-6.3-6.1c0.5-4,2.6-5.5,6.6-4.7c2.2,0.5,4.3,1.1,5.2,3.5
                C186,391.8,183.4,395.1,179.1,395.1z"/>
              <path className="st1" d="M200.3,393.2c-1.3-1.3-2.3-1.7-2.5-2.5c-0.9-3-1.4-6.1-2.4-9.1c-3.2-10.4-7.1-20.6-12.1-30.4
                c-0.5-1-0.9-2.2-1.3-3.2c0.4-0.3,0.8-0.7,1.2-1c0.7,0.5,1.6,0.9,1.9,1.6c1.6,3,3.3,6,4.6,9.1c2.6,6,5.1,12.1,7.3,18.2
                c1.5,4.1,2.7,8.3,3.8,12.5C201.3,389.5,200.6,391,200.3,393.2z"/>
              <path className="st1" d="M256.2,314c-11.6,7.6-23.8,14-36.3,20.1c-4.1,2-8.4,3.6-13.9,2.1c1-0.9,1.9-2.1,3.1-2.5
                c8.5-3.2,16.2-7.7,23.6-12.8c5.6-3.9,11.8-6.8,18.4-8.7c2.3-0.6,4.7-0.8,8.7-1.5C257.7,312.6,257.1,313.4,256.2,314z"/>
              <path className="st1" d="M351.8,358.2c-2.1-1.3-3.9-3-6-4.1c-4.9-2.7-9.8-5.6-15-7.5c-11.6-4.4-23.2-8.9-35.2-12.5
                c-4.6-1.4-8.9-3.9-13.4-5.8c-3.3-1.5-6.7-2.8-10.1-4.4c-0.7-0.3-1.2-1.2-1.9-1.9c2.4-1.5,4.1-0.4,5.9,0.4c4.7,2,9.4,4.1,14.1,6.1
                c4.3,1.8,8.5,3.5,12.9,5.1c11.2,4.1,22.6,7.7,33.6,12.3c5.8,2.4,10.9,6.4,16.3,9.7c0.7,0.4,1.1,1.4,2.2,3
                C353.4,358.4,352.4,358.6,351.8,358.2z"/>
            </g>
            <g>
              <g>
                <path d="M182.2,259.3c0.5-4.6,1-9.2,1.5-13.8c0.1-0.8-0.8-1.5-1.5-1.5c-0.9,0-1.4,0.7-1.5,1.5c-0.5,4.6-1,9.2-1.5,13.8
                  c-0.1,0.8,0.8,1.5,1.5,1.5C181.6,260.8,182.1,260.1,182.2,259.3L182.2,259.3z"/>
              </g>
            </g>
          </g>
          <g id="hairR">
            <g>
              <path className="st0" d="M232.3,45c2.4,3.9,42.7-15.2,61.5,40.8c8.1,24.2-6,39-6,39c7.4,52.8-21,45-21,45
                c-35.2,11.1-50.2,75.6-80.7,51.3L232.3,45z"/>
              <path className="st1" d="M276.1,74.9c6.6,4.3,11.8,13.3,8.8,21.1C283.8,88.1,279.7,82,276.1,74.9L276.1,74.9z"/>
            </g>
          </g>
          <g id="hairL">
            <g>
              <path className="st0" d="M274.1,78.6c9.4-8.4,16.8-86.7-69-66.4c0,0-68.5-42.3-115.7,30.6c0,0-7.1,10.8-6.5,33.2
                c-13.6,13.9-37.7,40.6,4.6,81.7c0,0,5.9-1,18.9-0.6c0,0,9.3,11.2,44.7,14.4c13.5,1.2-6.2,45.1,10.8,59.5L274.1,78.6z"/>
              <path className="st1" d="M220.4,26.4c16.5-2.9,32.4,6.8,36.8,23.1c-2.5-3-4.9-5.9-7.5-8.6C242.2,32,231.2,29.1,220.4,26.4L220.4,26.4z
                "/>
              <path className="st1" d="M260,39.2c9.1,5.5,12,18.4,9,28.1C267,57.4,265.4,48.1,260,39.2L260,39.2z"/>
              <path className="st1" d="M182.6,25.3c-17.3-5.4-35.6-1.3-50.2,9.1c1.5-1.7,3.1-3.3,4.9-4.8C149.5,19.1,168.7,16.7,182.6,25.3
                L182.6,25.3z"/>
              <path className="st1" d="M183.8,34c-9.5-0.7-20.2-2-27.9,4.7C160.5,28.3,175.4,28.6,183.8,34L183.8,34z"/>
              <path className="st1" d="M185.6,44.1c-5.7,1.3-10.2,1.3-16,0.9C173.9,41.1,180.7,41,185.6,44.1L185.6,44.1z"/>
              <path className="st1" d="M92.5,101c-9.1,1.8-4.5,14.1-3.8,20.5c0.2,1.4,0.9,4.9,1.1,6.2c0,0.3,0.2,1.3,0.2,1.6
                c-0.1,0.1,0.1,0.2-0.1,0.3c-0.1-0.3-0.1-0.3,0.1-0.1c-0.2,0.1-0.1-0.1-0.2-0.1c-0.2-0.3-0.6-1.1-0.8-1.4c-3.5-7-6.5-15.2-3.9-23
                C85.9,101.9,89.5,99.9,92.5,101L92.5,101z"/>
              <path className="st1" d="M88.5,96c-9.9,3.1-9.7,12.6-10.7,21.3c-0.3,3-0.7,6-1.3,9c-0.7-3-1-6-1.2-9.1C74.6,108.1,76.7,95.3,88.5,96
                L88.5,96z"/>
            </g>
          </g>
          <g id="ears">
            <g>
              <path className="st1" d="M109.7,199.4c-4.5,0-8-2.3-10.3-4c-12.9-9.6-18.1-24.8-13.5-39.6c0.8-2.5,1.9-4.7,3.2-6.6
                c1.3-1.8,3.3-3.3,5.2-3.9c0.7-0.2,1.4-0.3,2.2-0.3c5,0,11.8,4.5,12.7,9.9c0.5,3.4,0.8,7.8-2.4,11.8l1.8,1.9
                c2.3,2.4,2.5,5.1,2.6,7.1c0.1,0.8,0.1,1.6,0.3,2.2c1,3.5,1.5,7,1.8,9.7c0.1,0.7,0.3,1.4,0.5,2.2c0.6,2.1,1.3,4.8,0,7.8l-0.5,1.2
                l-1.3,0.2C111.3,199.3,110.5,199.4,109.7,199.4z"/>
              <path d="M96.6,147.6c4.2,0,9.6,4,10.2,7.8c0.6,3.7,0.5,7.1-2.2,10.3c-2.3,2.8-4,6.1-5.7,9.3c-0.9,1.7-0.7,3.3,2.2,3.9
                c1.7-2.6,3.6-5.3,5.7-8.6c2.5,2.6,1.6,5.8,2.4,8.3c0.9,3,1.4,6.2,1.8,9.3c0.3,2.8,2.1,5.5,0.7,8.7c-0.7,0.1-1.3,0.2-1.9,0.2
                c-3.4,0-6.3-1.6-8.9-3.5c-11.8-8.8-17-22.8-12.6-36.8c0.6-2.1,1.6-4.1,2.9-5.9c0.9-1.3,2.4-2.5,3.9-2.9
                C95.5,147.6,96,147.6,96.6,147.6 M96.6,142.6c-1,0-2,0.1-2.9,0.4c-2.5,0.7-4.9,2.5-6.6,4.8c-1.5,2.1-2.7,4.6-3.6,7.3
                c-5,15.9,0.5,32.1,14.4,42.3c2.6,1.9,6.6,4.5,11.8,4.5c0.9,0,1.9-0.1,2.8-0.2l2.7-0.5l1.1-2.5c1.6-3.8,0.7-7.2,0.1-9.5
                c-0.2-0.7-0.4-1.3-0.4-1.8c-0.3-2.8-0.8-6.5-1.9-10.1c-0.1-0.4-0.2-1-0.2-1.7c-0.2-2.2-0.5-5.6-3.3-8.6l-0.4-0.4
                c2.5-4.3,2.2-8.5,1.7-12C110.6,147.8,102.7,142.6,96.6,142.6L96.6,142.6z"/>
            </g>
         
            <g>
              <path className="st1" d="M262.8,194.9c-0.9,0-1.8-0.2-2.7-0.5l-1.5-0.6l-0.1-1.6c-0.1-1-0.1-2.2-0.1-3.7c0-0.8,0.1-1.7,0.1-2.6
                c0-0.3,0-0.6,0.1-0.9c0-0.3,0.1-0.7,0.1-1c0.6-6.5,2.1-15.3,3.6-18.6l1-2.2l2.2,0.9c0.2,0.1,0.5,0.2,0.7,0.3
                c-4.5-5.1-5.6-10.9-3.5-17.9l0.3-0.9l0.8-0.5c0.5-0.3,1.1-0.7,1.6-1.1c1.3-0.9,2.6-1.8,4.1-2.6c1.8-0.9,3.6-1.4,5.2-1.4
                c2.3,0,5.5,0.9,8,5.3c6.1,10.8,6.2,22.2,0.4,33.2c-2.7,5-7.2,12-15.6,15.2C266.2,194.3,264.6,194.9,262.8,194.9z"/>
              <path d="M274.8,142.6c2.3,0,4.3,1.3,5.8,4c5.8,10.2,5.8,20.6,0.3,30.7c-3.2,6-7.5,11.5-14.3,14.1c-1.3,0.5-2.5,0.9-3.8,0.9
                c-0.6,0-1.2-0.1-1.8-0.3c-0.1-0.9-0.1-2.1,0-3.4c0-0.8,0.1-1.6,0.1-2.5c0-0.3,0-0.6,0.1-0.9c0-0.3,0.1-0.6,0.1-1
                c0.6-6.8,2.1-14.9,3.4-17.8c0,0,0,0,0,0c2.3,1,3,3.8,4.7,5.7c0.8,0.9,1.8,1.5,3.3,1.8c0-1.6,0.5-3.1,0-4.2
                c-1.2-2.4-2.5-4.8-4.3-6.8c-3.9-4.4-5-9.3-3.1-15.7c1.8-1.2,3.5-2.5,5.4-3.5C272.2,143,273.5,142.6,274.8,142.6 M274.8,137.6
                c-2,0-4.1,0.6-6.3,1.7c-1.7,0.8-3.1,1.9-4.4,2.8c-0.5,0.4-1,0.7-1.5,1l-1.5,1l-0.5,1.7c-1.8,5.9-1.5,11.2,0.9,15.8l-1.2,2.7
                c-1.9,4.1-3.3,13.9-3.8,19.4c0,0.3-0.1,0.7-0.1,1c0,0.3-0.1,0.6-0.1,1c-0.1,1-0.1,1.9-0.1,2.7c-0.1,1.6,0,2.9,0.1,4l0.3,3.1
                l2.9,1.1c1.1,0.4,2.4,0.7,3.6,0.7c2.2,0,4.2-0.7,5.6-1.3c9.2-3.6,14.1-11.1,16.9-16.4c6.3-11.7,6.1-24-0.4-35.6
                C281.8,138.8,277.7,137.6,274.8,137.6L274.8,137.6z"/>
            </g>
          </g>
          <g id="face">
            <g>
              <path className="st1" d="M249.8,213.1c-8.2,30.7-35.8,52.6-65.7,52.3c-22.4-0.3-60.4-26.1-65-59.8c-8.8-65.4-8.1-95.3-8.1-96.8
                c0.1-1.6,3.1-34.2,24-45.8c2-1.1,3.8-2.2,5.6-3.1c14.3-8.2,22.2-12.6,46.9-12c36,0.9,58.6,24.3,59,24.7
                C274.3,99.6,250,212.1,249.8,213.1z"/>
              <path className="st0" d="M250.2,69.2c-1-1.1-24.3-25.2-62.5-26.2c-26.2-0.7-34.9,4.3-49.6,12.6c-1.7,1-3.5,2-5.4,3.1
                c-24,13.3-26.8,48.8-26.9,50.3v0.1c0,1.2-1,30,8,96.5c5,37,45.4,64.2,70.3,64.5c0.3,0,0.6,0,0.9,0c32,0,61.4-23.6,70.1-56.4
                C256.1,209,280,98.3,250.2,69.2z M184.1,263.8c-22.1-0.3-59.6-25.7-64-59c-8.7-64.5-8-93.9-7.9-95.4c0.1-1.5,3.1-33.7,23.7-45.1
                c1.9-1.1,3.7-2.1,5.5-3.1c14.1-8,21.9-12.4,46.2-11.8c35.5,0.9,57.8,24,58.1,24.3c27.3,26.7,3.4,137.5,3.2,138.5
                C240.8,242.5,213.6,264.1,184.1,263.8z"/>
            </g>
          </g>
          <g id="fringe">
            <g>
              <g>
                <path className="st0" d="M205.1,67.1l-11.4,2.8c0,0-16.1-0.6-36.9,18.9c-6,5.6-10.4,14.3-13,26.1c-2.8,12.6-32.1,22.3-33,21
                  c-22.5-34.5,13-69.1,13-69.1c21.1-19.1,66.8-28.6,74.6-15.2C205.5,63.6,205.1,67.1,205.1,67.1z"/>
              </g>
              <path className="st0" d="M188.4,67.2l16.9,2.7c0,0-4.3-5.8-6.5-5.7c-2.1,0.1,5.9-5.4,13.7,8c4.2,7.1,10.9,4.1,10.9,4.1l1,3
                c0,0,8.9-1,8.2,2.5l-3.8,1.2l5.3,3.8c0,0,5.2,38.6,34.1,48c18.2,5.9-11-62.8-11-62.8s-28.2-28.2-28.8-28.5
                c-0.6-0.3-37.4-9.1-54,13.7"/>
              <path className="st1" d="M127.2,110.4c-1.8-4.3,0.6-9.6,4.2-12.2C129.5,102.1,127.6,105.8,127.2,110.4L127.2,110.4z"/>
              <path className="st1" d="M118.8,115.8c-4-9.9,1.3-21.9,9.5-27.9C124,97.1,119.7,105.5,118.8,115.8L118.8,115.8z"/>
            </g>
          </g>

          <g id="mouth">
            <path className="st0" d="M163.8,223.6c17.2-0.4,35.9,1.7,50.7-8.8C202.3,229.2,180.4,227.7,163.8,223.6L163.8,223.6z"/>
            <path className="st0" d="M200.1,231.9c-3.2,5.4-12.1,7.1-16.5,2.3C189.5,234.6,194.5,233.3,200.1,231.9L200.1,231.9z"/>
          </g>
          <g id="nose">
            <g>
              <path className="st0" d="M177.5,138.6c1.1,1.6,1.9,3.1,2.6,4.7c0.7,1.6,1.4,3.3,1.9,5c1,3.4,1.6,7,1.6,10.6c0.1,3.4-0.1,6.9-0.2,10.3
                c-0.2,3.4-0.5,6.8-0.8,10.3c-0.2,1.7-0.4,3.4-0.7,5.2c-0.1,0.9-0.3,1.7-0.5,2.7c-0.1,0.2-0.1,0.5-0.2,0.7l-0.3,0.8
                c-0.1,0.4-0.2,0.6-0.3,0.9c-0.3,1.3-0.4,2.6-0.2,3.8c0.2,1.2,0.7,2.1,1.5,2.7c1.4,1.4,4.4,1.8,7.5,1.6c3.1-0.1,6.4-0.7,9.8-1.2
                l0,0c0.4,0,0.7,0.2,0.7,0.6c0,0.2-0.1,0.5-0.3,0.6c-1.4,1.1-2.9,1.9-4.5,2.7c-1.6,0.8-3.3,1.4-5.1,1.9c-1.8,0.4-3.7,0.7-5.8,0.5
                c-2-0.1-4.3-0.8-6.2-2.4c-0.9-0.8-1.7-1.7-2.3-2.8c-0.6-1-0.9-2.1-1.1-3.2c-0.4-2.2-0.3-4.3,0.2-6.3c0.1-0.5,0.3-1.1,0.5-1.5
                l0.1-0.4c0-0.1,0.1-0.3,0.1-0.5c0.2-0.7,0.3-1.5,0.5-2.3c0.3-1.6,0.5-3.2,0.8-4.9c0.9-6.6,1.5-13.3,1.9-20c0-0.8,0.1-1.6,0.1-2.4
                c0-0.8,0-1.6-0.1-2.4c-0.1-1.6-0.3-3.2-0.6-4.8c-0.3-1.6-0.7-3.2-1.2-4.7c-0.5-1.5-1-3.1-1.7-4.5l0-0.1c-0.3-0.7,0-1.5,0.6-1.8
                C176.3,137.8,177.1,138,177.5,138.6z"/>
            </g>
          </g>
          <g id="brownL">
            <path className="st0" d="M165.5,130.9c-1,0-21.7-5-22.8-5.1c-11.1-1-9.7,1.5-18.7,6c-1,0.5-4.4,0.8-7,1.6c5.2-8.3,10.8-16.1,16.8-17.6
              c6.3-1.6,14.9,1.4,21.2,2.3c1.2,0.2,5.4,1,8.8,1.8c2.7,0.6,7.7,1.4,9,4.5c0.8,1.9-1.3,5.1-1.3,5.6
              C169.5,130.4,167.5,131,165.5,130.9z"/>
          </g>
          <g id="brownR">
            <path className="st0" d="M205.2,129.7c1-0.1,21.4-6.3,22.4-6.4c11.1-1.6,9.8,1,19,5c1.1,0.5,4.4,0.6,7.1,1.2
              c-5.7-8-11.7-15.4-17.8-16.6c-6.3-1.2-14.8,2.3-21,3.5c-1.1,0.2-5.3,1.3-8.7,2.3c-2.6,0.7-7.6,1.9-8.7,5c-0.7,2,1.6,5,1.6,5.5
              C201.2,129.3,203.2,129.9,205.2,129.7z"/>
          </g>
          <g id="eyes">
            <g>
              <path className="st0" d="M160.2,148.7c4,1,7.7,1.9,12,2.9c-0.7-1.5-0.8-2.2-1.3-2.6c-12.6-10.5-30.7-9.7-42,1.7
                c-0.5,0.5-1,1.1-1.1,1.8c-0.1,0.6,0,1.5,0.4,2c0.4,0.4,1.4,0.8,1.9,0.6c1.3-0.6,2.5-1.5,3.7-2.2c1.6-1,2.9-2.5,5.1-2.1
                C138.9,150.7,147,144.3,160.2,148.7z"/>
              <path className="st0" d="M231.8,147.8c4,1,7.7,1.9,12,2.9c-0.7-1.5-0.8-2.2-1.3-2.6c-12.6-10.5-30.7-9.7-42,1.7
                c-0.5,0.5-1,1.1-1.1,1.8c-0.1,0.6,0,1.5,0.4,2c0.4,0.4,1.4,0.8,1.9,0.6c1.3-0.6,2.5-1.5,3.7-2.2c1.6-1,2.5-1.2,5.1-2.1
                C210.4,149.9,218.5,143.5,231.8,147.8z"/>
            </g>
          </g>
          <g id="pupil">
            <g>
              <circle className="eye st0" cx="150.5" cy="155.9" r="9.9"/>
              <circle className="eye st0" cx="221" cy="155.1" r="9.9"/>
            </g>
          </g>  
        </svg>
    )
 
}

